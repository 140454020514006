import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const RaceSection = ({ location }) => {
  const title="קטע מספר 8: מגשר רעים לבארי (חנות לה-מדווש)";
  return (
    <Layout path={location.pathname} title={title}>
      <SectionContent title={title}>
        <div>
          <p>
            <strong>אורך הקטע: 8.85 ק&quot;מ</strong>
          </p>
          <p>
            <strong>רמת הקושי: קלה</strong>
          </p>
          <p>
            <strong>תיאור הקטע</strong>:
          </p>
          <p>
            מגשר רעים עולים בכיוון צפון-מזרח על דרך עפר תלולה היוצאת מנחל הבשור,
            וממשיכה במקביל לכביש 232 כ-750 מטרים עד מפגש עם דרך אספלט משמאל
            (מערב). פונים אל הדרך. ממשיכים לרוץ על דרך האספלט המתעקלת ימינה לאחר
            כ-400 מטרים. ממשיכים עד לסיום דרך האספלט במפגש עם הירידה לנחל גרר.
            בנחל גרר עוקפים את שלולית המים הגדולה משמאל בהליכה זהירה על הסכר,
            וחוצים לגדה הצפונית, למפגש עם דרך עפר רחבה וסינגל בארי. פונים ימינה
            (מזרחה) על דרך העפר הרחבה במקביל לנחל גרר עד למפגש עם דרך עפר העולה
            שמאלה (צפונה) בק&quot;מ 3.4. עולים על הדרך וממשיכים איתה בכיוון כללי
            צפון כ-800 מטרים עד למפגש עם דרך עפר קצרה המובילה לדרך לבנה בסמוך
            למחפורת עפר ישנה (מחסן תחמושת מימי מלחמת העולם הראשונה). פונים ימינה
            לכיוון כללי צפון על הדרך הלבנה שבהמשך הופכת לדרך אספלט. ממשיכים על
            הדרך הראשית ומתעלמים מכל הפניות. בק&quot;מ 7.2, לוקחים את הפניה
            ימינה במזלג דרכים לכיוון קיבוץ בארי. לפני שער הקיבוץ פונים שמאלה
            לרחבת חניה גדולה של חנות לה-מדווש..
          </p>
          <p>
            <strong>טופוגרפיה</strong>: מגמת עליה
          </p>
          <p>
            <strong>מסלול</strong>: דרך אספלט, דרך לבנה.
          </p>
          <p>
            <strong>בטיחות</strong>: זהירות בחציית הסכר בנחל גרר.
          </p>
          <p>
            <strong>תחנת החלפה</strong> : ברחבת החניה של חנות לה-מדווש בכניסה
            לקיבוץ בארי.
          </p>
        </div>
        <div>
          <p>
            <iframe
              src="//www.gpsies.com/mapOnly.do?fileId=dzhdfbhprwkiqkil"
              width="100%"
              height="400"
              scrolling="no"
              title="sectionIframe"
            ></iframe>
          </p>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default RaceSection;
